"use client"

import {Suspense, useEffect, useState} from "react";
import {IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps} from "../../../lib/types/components";
import {
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_PLAY_URL,
    UTM_PLAYSTORE,
} from "../../../lib/utils/constants/GlobalConstants";
import {checkIsGetAppFb, isMobile} from "../../../lib/utils/constants/PagesConstants";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import styles from "./FloatingDownloadButtonComponentWithVideo.module.css";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import {useSearchParams} from "next/navigation";

const DownloadButton = (props: IFloatingDownloadButtonComponentProps) => {
    const {
        fixedDownloadButtonData,
        blinkTextDownload,
        pageName
    } = props;

    const searchParams = useSearchParams()

    const isGetAppFb: boolean = checkIsGetAppFb(pageName);
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);
    const [downloadBtnText, setDownloadBtnText] = useState<string | undefined>(blinkTextDownload);

    //function to toggle fixed download button popup
    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true)
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false)
        }
    }

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        const isUtmPlayStore = searchParams?.get('utm') === UTM_PLAYSTORE;
        const isGetAppPlay: boolean = pageName === GET_APP_PLAY_URL;
        if(isUtmPlayStore && isGetAppPlay) {
            setDownloadBtnText(fixedDownloadButtonData.text7 || "Upgrade to the full version of the app!");
        }
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        pageName: pageName
    }
    
    return (
        !isGetAppFb && blinkTextDownload ?
            <div
                className={multipleClassName("floatingDownloadButtonContainer", styles.floatingDownloadButtonContainer)}
                style={scrollPositionGame ? {bottom: "0px"} : {bottom: "-180px"}}
            >
                <p className={styles.floatingDownloadButtonText}>
                    {downloadBtnText}
                </p>

                <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
            </div>
            :
            <></>
    )
}

const FloatingDownloadButtonComponentV2 = (props: IFloatingDownloadButtonComponentProps) => {
    return (
        <Suspense>
            <DownloadButton {...props} />
        </Suspense>
    )
}

export default FloatingDownloadButtonComponentV2;