import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import {IFooterData} from "../../../lib/types/common";
import {footerLinkComponentUI} from "./FooterComponentV2";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./FooterComponentV2Desktop.module.css"
import styless from "./FooterComponentV2Desktop-1.module.css"
import CustomImageComponent from "../CustomImage/CustomImageComponent";

const SubFooterComponentV1Desktop = (footerData: IFooterData) => {
    return <>
    <div className={multipleClassName("col-md-7", "col-12", styless.footerTopLeftV2)}>
        <div className={styless.gamesSectionV2}>
            <div className={"row"}>
                <div className={"col"}>
                    <p className={styless.allHeadingsV2}>
                        {footerData.gamesHeading}
                    </p>
                </div>
            </div>
            {footerData.gamesSections && <div className={"row"}>{
                footerData.gamesSections.map((item, index) => {
                    return (
                        <div className={multipleClassName("col-4", styless.linksCol)} key={"footerData_gamesSections_" + index}>
                            <CustomLinkComponent
                                href={item.path}
                                mobStyle={{
                                    color: "#d8d8d8",
                                    textDecoration: "none"
                                }}
                                desktopStyle={{
                                    color: "#d8d8d8",
                                    textDecoration: "none"
                                }}
                            >
                                {item.text}
                            </CustomLinkComponent>
                        </div>
                    )
                })
            }</div>}
        </div>

        <div className={styless.blogSectionV2}>
            <div className={"row"}>
                <div className={"col"}>
                    <p className={styless.allHeadingsV2}>
                        {footerData.blogHeading}
                    </p>
                </div>
            </div>
            {footerData.blogSections && <div className={"row"}>{
                footerData.blogSections.map((item, index) => {
                    return (
                        <div className={multipleClassName("col-4", styless.linksCol)} key={"footerData_blogSections_" + index}>
                            <a href={item.path}>
                                {item.text}
                            </a>
                        </div>
                    )
                })
            }</div>}
        </div>

        <div className={styless.policiesSectionV2} id="footer_policies_section">{
            footerData.policiesHeading &&
            <>
                <div className={"row"}>
                    <div className={"col"}>
                        <p className={styless.allHeadingsV2}>
                            {footerData.policiesHeading}
                        </p>
                    </div>
                </div>
                <div className={"row"}>{
                    footerData.policiesSections.map((item, index) => {
                        return (
                            <div className={multipleClassName("col-4", styless.linksCol)} key={"footerData_policiesSections_" + index}>
                                <a href={item.path}>
                                    {item.text}
                                </a>
                            </div>
                        )
                    })
                }</div>
            </>
        }</div>

        <div className={styless.aboutUsSectionV2} id="footer_aboutus_section">
            <div className={"row"}>
                <div className={"col"}>
                    <p className={styless.allHeadingsV2}>
                        {footerData.footerHeading}
                    </p>
                </div>
            </div>
            <div className={"row"}>{
                footerData.footerSections.map((item, index) => {
                    let key = item.key;
                    return (
                        <div className={multipleClassName("col-4", styless.linksCol)} key={"footerData_footerSections_" + index}>
                            {footerLinkComponentUI(key, index, item, footerData.downloadLink)}
                        </div>
                    )
                })
            }</div>
        </div>
    </div>
</>
}
const FooterComponentV2Desktop = (footerData: IFooterData) => {

    const fontColor = footerData.colorMode ? "#ffffff" : "#1e092d";
    
    return ( (footerData.gamesSectionsV2 && footerData.gamesSectionsV2.length !==0) ?
        <>
            <div className={multipleClassName("col-md-8", "col-12", styles.footerTopLeftV2)}>
                <div className={multipleClassName(styles.winzoFooterImg)}>
                    <CustomLinkComponent href="/">
                        <CustomImageComponent
                            layout={"fill"}
                            objectFit={"contain"}
                            src={footerData.colorMode ? footerData.winzoLogoV2 : footerData.winzoLogoV2Light}
                            alt="winzo games logo"
                            className={styles.winzoFooterImg}
                        />
                    </CustomLinkComponent>
                </div>
                {footerData.socialMedia && <div>
                    <div className={styles.allHeadingsV2}>
                        {footerData.mobileText}
                    </div>
                    <div className={styles.line} style={{width: '50%', backgroundColor: footerData.colorMode ? "#ffffff33": "#1e092d33"}}></div>
                    <div className={styles.socialFooterContainer}>
                        {
                            footerData.socialMedia?.map((item, index) => {
                                let id = item.link.replace(/[^\w\s]/gi, '_')
                                return (
                                    <a id={id} target="_blank" key={"footerData_socialMediaV2_" + index}
                                        href={item.link}
                                        rel={"noreferrer"} className={styles.socialImageContainer}>
                                        <CustomImageComponent
                                            layout={"fill"}
                                            objectFit={"contain"}
                                            src={item.src}
                                            alt={"social-media-image"}
                                        />
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>}
                <div className="row" style={{marginTop: "0.2%"}}>
                    {footerData.gamesSectionsV2 && <div className={footerData.policiesSections || footerData.footerSections || footerData.blogSections ? "col-md-6" : "col-md-12"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <p className={styles.allHeadingsV2}>
                                    {footerData.gamesHeading}
                                </p>
                                <div className={styles.line} style={{backgroundColor: footerData.colorMode ? "#ffffff33": "#1e092d33"}}></div>
                            </div>
                        </div>
                        {footerData.gamesSectionsV2 && <div className={"row"}>{
                            footerData.gamesSectionsV2.map((item, index) => {
                                return (
                                    <div className={footerData.policiesSections || footerData.footerSections || footerData.blogSections ? "col-md-6" : "col-md-3"} style={{marginBottom: "2%"}}>
                                        <div className={multipleClassName("row")} style={{margin: "0 0 7.5%"}}>
                                            <CustomImageComponent
                                                layout="fixed"
                                                width={16}
                                                height={16}
                                                src={footerData.colorMode ? item.icon : item.iconLight}
                                                alt={""}
                                                className={styles.gamesCategoryIcon}
                                            />
                                            <div className={multipleClassName(styles.gamesCategory)}>{item.type}</div>
                                        </div>
                                        {item.games.map((subItems, index)=>{
                                            return (
                                                <div className={multipleClassName(styles.linksCol)} key={"footerData_gamesSectionsV2_" + index}>
                                                    <CustomLinkComponent
                                                        href={subItems.path}
                                                        mobStyle={{
                                                            color: fontColor,
                                                            textDecoration: "none"
                                                        }}
                                                        desktopStyle={{
                                                            color: fontColor,
                                                            textDecoration: "none"
                                                        }}
                                                    >
                                                        {subItems.text}
                                                    </CustomLinkComponent>
                                                </div>
                                            )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }</div>}
                    </div>}
                    <div className={"col"}>
                        {(footerData.blogSections?.length !== 0) && <div className={styles.blogSectionV2}>
                            {footerData.blogHeading && <div className={"row"}>
                                <div className={"col"}>
                                    <p className={styles.allHeadingsV2}>
                                        {footerData.blogHeading}
                                    </p>
                                    <div className={styles.line} style={{backgroundColor: footerData.colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                </div>
                                </div>}
                            {footerData.blogSections && <div className={"row"}  style={{marginTop: "2%"}}>{
                                footerData.blogSections.map((item, index) => {
                                    return (
                                        <div className={multipleClassName(footerData.gamesSectionsV2 ? "col-md-6" : "col-md-3", styles.linksCol)} key={"footerData_blogSections_" + index}>
                                            <a href={item.path} style={{color: fontColor}}>
                                                {item.text}
                                            </a>
                                        </div>
                                    )
                                })
                            }</div>}
                            </div>}
                        {(footerData.footerSections?.length !== 0) && <div className={styles.aboutUsSectionV2} id="footer_aboutus_section">
                                {footerData.footerHeading && <div className={"row"}>
                                    <div className={"col"}>
                                        <p className={styles.allHeadingsV2}>
                                            {footerData.footerHeading}
                                        </p>
                                        <div className={styles.line} style={{backgroundColor: footerData.colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    </div>
                                </div>}
                                <div className={"row"} style={{marginTop: "2%"}}>{
                                    footerData.footerSections?.map((item, index) => {
                                        let key = item.key;
                                        return (
                                            <div className={multipleClassName(footerData.gamesSectionsV2 ? "col-md-6" : "col-md-3", styles.linksCol)} key={"footerData_footerSections_" + index}>
                                                {footerLinkComponentUI(key, index, item, footerData.downloadLink, fontColor)}
                                            </div>
                                        )
                                    })
                                }</div>
                        </div>}
                        {(footerData.policiesSections?.length !== 0) && <div className={styles.policiesSectionV2} id="footer_policies_section">{
                                footerData.policiesHeading &&
                                <>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <p className={styles.allHeadingsV2}>
                                                {footerData.policiesHeading}
                                            </p>
                                            <div className={styles.line} style={{backgroundColor: footerData.colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                        </div>
                                    </div>
                                    <div className={"row"} style={{marginTop: "2%"}}>{
                                        footerData.policiesSections?.map((item, index) => {
                                            return (
                                                <div className={multipleClassName(footerData.gamesSectionsV2 ? "col-md-6" : "col-md-3", styles.linksCol)} key={"footerData_policiesSections_" + index}>
                                                    <a href={item.path} style={{color: fontColor}}>
                                                        {item.text}
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }</div>
                                </>
                        }</div>}
                    </div>
                </div>
            </div>
        </> :
        <SubFooterComponentV1Desktop {...footerData}/>
    )
}

export default FooterComponentV2Desktop