import {useState} from "react";
import {ICountrySelectComponentProps} from "../../../lib/types/components.us";
import {countryCodes} from "../../../lib/utils/constants/CountryCode";
import {COUNTRY} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import {debounce, getCountryFromLocale} from "../../../lib/utils/constants/PagesConstants";
import styles from "./CountrySelectComponent.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const CountrySelect = (props: ICountrySelectComponentProps) => {
    const countryCode = getCountryFromLocale(props.locale) === COUNTRY.INDIA ? "+91" : props.countryCode
    const setCountryCode = props.setCountryCode
    const [searchText, setSearchText] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);
    const isGermanyPage = getCountryFromLocale(props.locale) === COUNTRY.GERMANY;

    const getDebounceText = debounce<string>(setSearchText, 500);

    const reset = (dropDownValue: boolean) => {
        setShowDropDown(dropDownValue);
        setSearchText("");
    }

    const handleBlur = (event) => {
        const target: Element = event.relatedTarget as Element
        if (!target || (target.id !== "countrySearch" && target !== event.currentTarget)) {
            reset(false)
        }
    }

    return (
        <div className={styles.selectionContainer} style={{
            pointerEvents: `${props.disableAppDownload
                ? "none"
                : "auto"}`
        }} tabIndex={0} onBlur={handleBlur}>
            <div className={multipleClassName(styles.countryCodeInput, isGermanyPage ? styles.countryCodeInputV2 : "")} onClick={() => reset(!showDropDown)}>
                <p>{countryCode}</p>
                <svg width="9" height="7" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.5 11 13 0H0l6.5 11z" fill="#fff"/>
                </svg>
            </div>
            {
                showDropDown && getCountryFromLocale(props.locale) !== COUNTRY.INDIA &&
                <div className={styles.countryDropDown}>
                    <div className={styles.srchContainer}>
                        <input type="search" id={"countrySearch"}
                               className={multipleClassName("form-control", styles.srchBox)}
                               placeholder={`${props.placeholder ? props.placeholder : "Search Country"}`}
                               autoFocus={true}
                               onChange={(e) => {
                                   getDebounceText(e.target.value);
                               }}/>
                    </div>
                    <div className={styles.countries}>
                        {
                            countryCodes.map((item, idx) => {
                                if (item.name.toLowerCase().includes(searchText) || item.dial_code.includes(searchText)) {
                                    return <div className={styles.countryElement} key={"countryCodes_" + idx}
                                                onClick={() => {
                                                    setCountryCode(item.dial_code);
                                                    reset(false);
                                                }}>{`${item.name} (${item.dial_code})`}</div>
                                }
                            })
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default CountrySelect;