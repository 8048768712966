"use client"
import HTMLReactParser from "html-react-parser";
import Analytics from "../../../lib/analytics/Analytics";
import {CareerPageClick} from "../../../lib/analytics/AnalyticsEvents";
import {IFooterComponentProps} from "../../../lib/types/components";
import {
    BUG_BOUNTY,
    CAREER_KEY,
    DOWNLOAD_KEY,
    GET_APP_URL
} from "../../../lib/utils/constants/GlobalConstants";
import {checkIsGetAppAF, handleFooterDownloadClick} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FooterComponentV2Desktop from "./FooterComponentV2Desktop";
import styles from "./FooterComponentV2.module.css"
import styless from "./FooterComponentV2-1.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import { useDownloadContext } from "../../../lib/context/CommonContext";

export const footerLinkComponentUI = (key, index, item, downloadLink, fontColor?) => {

    return (
        <>
            {
                key === CAREER_KEY ?
                    <a href={item.path} style={{color: fontColor}} onClick={() => {
                        Analytics.getInstance().logEvent(CareerPageClick);
                    }} className={styles.link}>
                        {item.text}
                    </a> :
                    key === DOWNLOAD_KEY ?
                        <CustomLinkComponent mobStyle={{color: fontColor}} desktopStyle={{color: fontColor}} href={downloadLink}>
                                <span onClick={() => handleFooterDownloadClick()}>
                                    {item.text}
                                </span>
                        </CustomLinkComponent>
                        : key === BUG_BOUNTY ?
                            <a href={item.path} style={{color: fontColor}} className={styles.link}>
                                {item.text}
                            </a>
                            :
                            <CustomLinkComponent href={item.path} mobStyle={{color: fontColor}} desktopStyle={{color: fontColor}}>
                                {item.text}
                            </CustomLinkComponent>
            }
        </>
    )
}

const SubFooterComponentV1 = (props) => {
    const {
        footerData,
        membersData,
        withdrawalPartnerData,
        isUtmHotstar,
        pageName,
    } = props;

    const { downloadUrl: downloadLink } = useDownloadContext();
    return (
      <div
        className={multipleClassName(
          "container-fluid",
          styless.footerContainerV2
        )}
      >
        <div className="container">
          <div className={multipleClassName("row", styless.footerTop)}>
            {
              <FooterComponentV2Desktop
                {...footerData}
                downloadLink={downloadLink}
              />
            }

            <div
              className={multipleClassName(
                "col-md-5",
                "col-12",
                styless.footerTopRight
              )}
            >
              <div className={styless.footerNavs}>
                <h2 className={styless.headingMobile}>
                  {footerData.mobileText}
                </h2>
                <div className={styless.winzoFooterImg}>
                  <CustomLinkComponent href="/">
                    <CustomImageComponent
                      layout={"fill"}
                      objectFit={"contain"}
                      src={footerData.winzoLogo}
                      alt="winzo games logo"
                    />
                  </CustomLinkComponent>
                </div>
                <div className={styless.socialFooterContainer}>
                  {footerData.socialMedia.map((item, index) => {
                    let id = item.link.replace(/[^\w\s]/gi, "_");
                    return (
                      <a
                        id={id}
                        target="_blank"
                        key={"footerData_socialMedia_" + index}
                        href={item.link}
                        rel={"noreferrer"}
                        className={styless.socialImageContainer}
                      >
                        <CustomImageComponent
                          layout={"fill"}
                          objectFit={"contain"}
                          src={item.src}
                          alt={"social-media-image"}
                        />
                      </a>
                    );
                  })}
                </div>

                {membersData && (
                  <div className={styless.membersDataContainer}>
                    <h3 className={styless.membersDataHeading}>
                      {membersData.heading}
                    </h3>
                    <div className={styless.memberContainer}>
                      {membersData.list.map((item, index) => {
                        return (
                          <div
                            className={styless.memberImageContainer}
                            key={"membersData_list_" + index}
                          >
                            <CustomImageComponent
                              key={index}
                              src={item.src}
                              alt={item.alt}
                              objectFit={"contain"}
                              layout="fill"
                              objectPosition={"center center"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={styless.ourPartnerContainer}>
                  {withdrawalPartnerData && (
                    <p className={styless.membersDataHeading}>
                      {pageName === GET_APP_URL && isUtmHotstar
                        ? footerData.ourPartnersText
                        : footerData.paymentPartnersText}
                    </p>
                  )}
                  {withdrawalPartnerData && withdrawalPartnerData.footerImg && (
                    <div
                      className={multipleClassName(
                        styless.paymentContainer,
                        styless.paymentContainerDesktop
                      )}
                    >
                      <CustomImageComponent
                        layout="fixed"
                        width={300}
                        height={25}
                        src={withdrawalPartnerData.footerImg.src}
                        alt={withdrawalPartnerData.footerImg.alt}
                      />
                    </div>
                  )}
                  {withdrawalPartnerData &&
                    withdrawalPartnerData.footerImgV2 && (
                      <div
                        className={multipleClassName(
                          styless.paymentContainer,
                          styless.paymentContainerMobile
                        )}
                      >
                        <CustomImageComponent
                          layout={"fill"}
                          objectFit={"contain"}
                          src={withdrawalPartnerData.footerImgV2.src}
                          alt={withdrawalPartnerData.footerImgV2.alt}
                        />
                      </div>
                    )}
                </div>
                {withdrawalPartnerData && (
                  <hr className={styless.lineSeparator}></hr>
                )}
                <div className={styless.colMobile} id="footer_aboutus_section">
                  <div className="row no-gutters">
                    <p
                      className={multipleClassName("col", styless.allHeadingsV2)}
                    >
                      {footerData.footerHeading}
                    </p>
                  </div>
                  <div className={"row no-gutters"}>
                    {footerData.footerSections.map((item, index) => {
                      let key = item.key;
                      return (
                        <div
                          className={multipleClassName(
                            "col-6",
                            styless.linksCol
                          )}
                          key={"footerData.footerSections_" + index}
                        >
                          {footerLinkComponentUI(
                            key,
                            index,
                            item,
                            downloadLink
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {footerData.footerHeading && (
                  <hr className={styless.lineSeparator}></hr>
                )}
                {footerData.policiesHeading && (
                  <div
                    className={styless.colMobile}
                    id="footer_policies_section"
                  >
                    <div className="row no-gutters">
                      <p
                        className={multipleClassName(
                          "col",
                          styless.allHeadingsV2
                        )}
                      >
                        {footerData.policiesHeading}
                      </p>
                    </div>
                    <div className={"row no-gutters"}>
                      {footerData.policiesSections.map((item, index) => {
                        return (
                          <div
                            className={multipleClassName(
                              "col-6",
                              styless.linksCol
                            )}
                            key={"footerData.policiesSections_" + index}
                          >
                            <a href={item.path} key={index}>
                              {item.text}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <hr className={styless.lineSeparator}></hr>
                <div className={styless.disclaimerContainer}>
                  <h3 className={styless.disclaimerHeading}>
                    {footerData.disclaimer.heading}
                  </h3>
                  <p className={styless.disclaimerText}>
                    {footerData.disclaimer.text}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className={styless.biggerLineSeparator}></hr>
          <div className={multipleClassName("row", styless.footerBottom)}>
            <div className={multipleClassName("col", styless.copyright)}>
              <p>
                <span>
                  © {new Date().getFullYear()}
                  {footerData.rightsReservedText}{" "}
                </span>
                {footerData.footerLinks.map((item, index) => {
                  let id = item.path.replace(/[^\w\s]/gi, "_");
                  return (
                    <span
                      id={id}
                      key={"footerData.footerLinks_" + index}
                      className={styless.footerLinks}
                    >
                      <CustomLinkComponent href={item.path}>
                        {item.text}
                      </CustomLinkComponent>{" "}
                      -{" "}
                    </span>
                  );
                })}
                {footerData.linkText && (
                  <span>{HTMLReactParser(footerData.linkText)}</span>
                )}
              </p>
              {checkIsGetAppAF(pageName) && <p>{footerData.addressText}</p>}
            </div>
          </div>
        </div>
      </div>
    );
}

const FooterComponentV2 = (props: IFooterComponentProps) => {

    const {
        footerData,
        membersData,
        withdrawalPartnerData,
        isUtmHotstar,
        pageName,
        colorMode,
    } = props;
    const { downloadUrl: downloadLink } = useDownloadContext();

    const bgColor = colorMode ? "#1e092d" : "#e8e6ea";
    const fontColor = colorMode ? "#ffffff" : "#1e092d";

    return (
        footerData ? (footerData.gamesSectionsV2 && footerData.gamesSectionsV2.length !==0) ? 
            <div id="footerComponent" className={multipleClassName("container-fluid", styles.footerContainerV2)} style={{backgroundColor: bgColor, color: fontColor}}>
                <div className="container">
                    <div className={multipleClassName("row", styles.footerTop)}>
                        {<FooterComponentV2Desktop {...footerData} downloadLink={downloadLink} colorMode={colorMode}/>}
                        <div className={multipleClassName("col-md-4", "col-12", styles.footerTopRight)}>
                            <div className={styles.footerNavs}>
                                <div className={styles.extraSpace}></div>
                                {footerData.socialMedia && <div>
                                    <h2 className={styles.headingMobile}>{footerData.mobileText}</h2>
                                    <div className={styles.lineMobile} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    <div className={styles.socialFooterContainer}>
                                        {
                                            footerData.socialMedia?.map((item, index) => {
                                                let id = item.link.replace(/[^\w\s]/gi, '_')
                                                return (
                                                    <a id={id} target="_blank" key={"footerData_socialMediaV2_" + index}
                                                    href={item.link}
                                                    rel={"noreferrer"} className={styles.socialImageContainer}>
                                                        <CustomImageComponent
                                                            layout={"fill"}
                                                            objectFit={"contain"}
                                                            src={item.src}
                                                            alt={"social-media-image"}
                                                        />
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                                {membersData && membersData.list && membersData.list.length!==0 && <div className={styles.membersDataContainer}>
                                    <h3 className={styles.membersDataHeading}>{membersData.heading}</h3>
                                    <div className={styles.line} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    <div className={styles.memberContainer}>
                                        {
                                            membersData.list.map((item, index) => {
                                                return (
                                                    <div className={styles.memberImageContainer}
                                                         key={"membersData_list_" + index}>
                                                        <CustomImageComponent
                                                            key={index}
                                                            src={item.src}
                                                            alt={item.alt}
                                                            objectFit={"contain"}
                                                            layout="fill"
                                                            objectPosition={"center center"}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                                <div className={styles.ourPartnerContainer}>
                                    {withdrawalPartnerData && withdrawalPartnerData.footerImgV3 && <div>
                                        <p className={styles.membersDataHeading}>
                                            {
                                                (pageName === GET_APP_URL) && isUtmHotstar
                                                    ? footerData.ourPartnersText
                                                    : footerData.paymentPartnersText
                                            }
                                        </p>
                                        <div className={styles.line} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    </div>}
                                    {withdrawalPartnerData && withdrawalPartnerData.footerImgV3 &&
                                        <div
                                            className={multipleClassName(styles.paymentContainer, styles.paymentContainerDesktop)}>
                                            {withdrawalPartnerData.footerImgV3?.map((item, index) => {
                                                return (
                                                <div style={{position: "relative", height: "100%", width: "90px", marginRight: "10px"}}>
                                                    <CustomImageComponent
                                                    layout={"fill"}
                                                    objectFit={"contain"}
                                                    objectPosition={"center center"}
                                                    src={colorMode ? item.src : item.srcLight!}
                                                    alt={item.alt}
                                                    className={styles.paymentContainerDesktop}
                                                    />
                                                </div>)
                                            })}
                                        </div>}
                                    {withdrawalPartnerData && withdrawalPartnerData.footerImgV2 &&
                                        <div
                                            className={multipleClassName(styles.paymentContainer, styles.paymentContainerMobile)}>
                                            {withdrawalPartnerData.footerImgV3?.map((item, index) => {
                                                return (
                                                    <div style={{position: "relative", height: "100%", width: "90px", marginRight: "10px"}}>
                                                        <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        objectPosition={"center center"}
                                                        src={colorMode ? item.src : item.srcLight!}
                                                        alt={item.alt}
                                                        />
                                                    </div>)
                                            })}
                                        </div>}
                                </div>
                                {footerData.footerHeading && footerData.footerSections && <div className={styles.colMobile} id="footer_aboutus_section">
                                    <div className="row no-gutters">
                                        <p className={multipleClassName("col", styles.allHeadingsV2)}>
                                            {footerData.footerHeading}
                                        </p>
                                    </div>
                                    <div className={styles.line} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    <div className={"row no-gutters"} style={{margin: "2% 6%"}}>{
                                        footerData.footerSections.map((item, index) => {
                                            let key = item.key;
                                            return (
                                                <div className={multipleClassName("col-6", styles.linksCol)}
                                                     key={"footerData.footerSections_" + index}
                                                     style={{color: fontColor}}>
                                                    {footerLinkComponentUI(key, index, item, downloadLink, fontColor)}
                                                </div>
                                            )
                                        })
                                    }</div>
                                </div>}
                                {
                                    footerData.policiesHeading &&
                                    <div className={styles.colMobile} id="footer_policies_section">
                                        <div className="row no-gutters">
                                            <p className={multipleClassName("col", styles.allHeadingsV2)}>
                                                {footerData.policiesHeading}
                                            </p>
                                        </div>
                                        <div className={styles.line} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                        <div className={"row no-gutters"} style={{margin: "2% 6%"}}>{
                                            footerData.policiesSections?.map((item, index) => {
                                                return (
                                                    <div className={multipleClassName("col-6", styles.linksCol)}
                                                         key={"footerData.policiesSections_" + index}>
                                                        <a href={item.path} key={index} style={{color: fontColor}}>
                                                            {item.text}
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }</div>
                                    </div>
                                }
                                <div className={styles.disclaimerContainer}>
                                    <h3 className={styles.disclaimerHeading}>{footerData.disclaimer.heading}</h3>
                                    <div className={styles.line} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33"}}></div>
                                    <p className={styles.disclaimerText}>{footerData.disclaimer.text} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.lineMobile} style={{backgroundColor: colorMode ? "#ffffff33": "#1e092d33", marginTop: "5.5%"}}></div>
                    <div className={multipleClassName("row", styles.footerBottom)}>
                        <div className={multipleClassName("col", styles.copyright)}>
                            <p>
                                    <span>
                                        © {new Date().getFullYear()}{footerData.rightsReservedText}{" "}
                                    </span>
                                {
                                    footerData.footerLinks.map((item, index) => {
                                        let id = item.path.replace(/[^\w\s]/gi, '_')
                                        return (
                                            <span id={id} key={"footerData.footerLinks_" + index}>
                                                    <CustomLinkComponent
                                                        mobStyle={{color: fontColor}}
                                                        desktopStyle={{color: fontColor}}
                                                        href={item.path}>{item.text}</CustomLinkComponent> - {" "}
                                                </span>
                                        )
                                    })
                                }
                                {
                                    footerData.linkText && <span>
                                        {HTMLReactParser(footerData.linkText)}
                                      </span>
                                }
                            </p>
                            {
                                checkIsGetAppAF(pageName) && (
                                    <p>{footerData.addressText}</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div> : <SubFooterComponentV1 {...props}/>
            :
            <></>
    )
}

export default FooterComponentV2;