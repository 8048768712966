"use client"
import H2TrapeziumBG from "../TrapeziumBackground/H2TrapeziumBG";
import {IWinzoUSPComponentProps} from '../../../lib/types/components';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./WinzoUSPComponentV2.module.css"
import { useEffect, useState } from "react";
import useIsMobile from "../../../lib/customHooks/isMobile";
import { WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN, getWebsiteEnvironment } from "../../../lib/utils/constants/EnvironmentConstants";

const WinzoUSPComponentV2 = (props: IWinzoUSPComponentProps) => {

    const {winzoUSPData, padding = "18px 0px 1vw"} = props;
    const {isMobileDevice} = useIsMobile()
    const [heading, setHeading] = useState(winzoUSPData?.heading)

    useEffect(() => {
      if (
        [WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(
          getWebsiteEnvironment().baseUrl
        ) && winzoUSPData
      ) {
        if (isMobileDevice) {
            setHeading(winzoUSPData.headingMob || winzoUSPData.heading)
        } else {
            setHeading(winzoUSPData.heading)
        }
      }
    }, [isMobileDevice]);

    return (
        winzoUSPData && Object.keys(winzoUSPData).length ?
            <H2TrapeziumBG>
                <div className={multipleClassName("container-fluid", styles.parentContainerUSP)}
                     style={{padding: padding}}>
                    <div className={multipleClassName("container", styles.uspContainer)}>
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>
                                {heading}
                            </h2>
                        </div>
                        <div className="row">
                            {winzoUSPData.imageData.map((item, index) => {
                                return (
                                    <div className={multipleClassName("col", styles.itemContainer)} key={"winzoUSPData.imageData_" + index}>
                                        <div className={styles.imgContainer}>
                                            {item.srcV2 && <CustomImageComponent
                                                src={item.srcV2}
                                                className={styles.uspImg}
                                                alt={"winzo-features"}
                                                layout={"fill"}
                                                objectFit="contain"
                                                objectPosition={"center"}
                                            />}
                                        </div>
                                        <div className={styles.content}>
                                            <p className={styles.itemNumber}>{item.text1}</p>
                                            <p className={styles.itemQuantity}>{item.text2}</p>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default WinzoUSPComponentV2;