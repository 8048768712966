"use client";

import { useState } from "react";
import {
  GAMES_TO_SHOW_INITIALLY,
  GAMES_TO_SHOW_INITIALLY_V2,
} from "../../../lib/utils/constants/GlobalConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import styles from "./FooterLinksSectionV2.module.css";
import styless from "./FooterLinksSectionV2-1.module.css";
import { multipleClassName } from "../../../lib/utils/helpers/helper";

const SubFooterLinksSectionComponentV1 = (props) => {
  const { footerData } = props;
  const [readMore, setReadMore] = useState(false);
  const showMore = () => {
    setReadMore((prev) => !prev);
  };

  const linkComponentUI = (index: number, item: any) => {
    return (
      <li key={"linkComponent_UI_" + index}>
        <CustomLinkComponent href={item.path}>{item.text}</CustomLinkComponent>
      </li>
    );
  };

  const anchorComponentUI = (index: number, item: any) => {
    return (
      <li key={"anchorComponent_UI_" + index}>
        <a href={item.path}>{item.text}</a>
      </li>
    );
  };

  return footerData && footerData.gamesSections ? (
    <div
      className={multipleClassName(
        "container-fluid",
        styless.footerLinksSectionV2
      )}
    >
      {footerData.blogHeading && (
        <>
          <div className={"row"}>
            <div className={multipleClassName("col-4", styless.lineParentV2)}>
              <div className={styless.leftLineV2}></div>
            </div>
            <div
              className={multipleClassName(
                "col-4",
                styless.headingFooterLinkV2
              )}
              style={{
                fontSize: footerData.blogHeading.length > 12 ? 10 : 15,
              }}
            >
              {footerData.blogHeading}
            </div>
            <div className={multipleClassName("col-4", styless.lineParentV2)}>
              <div className={styless.rightLineV2}></div>
            </div>
          </div>
          {footerData.blogSections && (
            <div className={"row"}>
              <div className={"col-12"}>
                <ul className={styless.footerUListV2}>
                  {footerData.blogSections.map((item, index) => {
                    return anchorComponentUI(index, item);
                  })}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      <div className={"row"}>
        <div className={multipleClassName("col-4", styless.lineParentV2)}>
          <div className={styless.leftLineV2}></div>
        </div>
        <div
          className={multipleClassName("col-4", styless.headingFooterLinkV2)}
          style={{ fontSize: footerData.blogHeading?.length > 12 ? 10 : 15 }}
        >
          {footerData.gamesHeading}
        </div>
        <div className={multipleClassName("col-4", styless.lineParentV2)}>
          <div className={styless.rightLineV2}></div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-12"}>
          <ul className={styless.footerUListV2}>
            {footerData.gamesSections.map((item, index) => {
              return !readMore
                ? index < GAMES_TO_SHOW_INITIALLY
                  ? linkComponentUI(index, item)
                  : null
                : linkComponentUI(index, item);
            })}
          </ul>
        </div>
      </div>
      <div className={styless.readMoreDivV2} onClick={() => showMore()}>
        {!readMore ? (
          <div style={{ marginRight: "5px" }}>{footerData.readMoreText}</div>
        ) : (
          <div style={{ marginRight: "5px" }}>{footerData.readLessText}</div>
        )}
        <div
          className={styless.readMoreImageV2}
          style={{
            transform: `${!readMore ? "rotate(180deg)" : "rotate(0deg)"}`,
          }}
        >
          <CustomImageComponent
            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/showMoreIconV2.svg"
            layout={"fill"}
            objectFit={"contain"}
            alt={"about-us-image"}
          />
        </div>
      </div>
      <div className={"row"}>
        <div className={multipleClassName("col-4", styless.lineParentV2)}>
          <div className={styless.leftLineV2}></div>
        </div>
        <div
          className={multipleClassName("col-4", styless.headingFooterLinkV2)}
          style={{
            fontSize: footerData.policiesHeading.length > 12 ? 10 : 15,
          }}
        >
          {footerData.policiesHeading}
        </div>
        <div className={multipleClassName("col-4", styless.lineParentV2)}>
          <div className={styless.rightLineV2}></div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-12"}>
          <ul className={styless.footerUListV2}>
            {footerData.policiesSections.map((item, index) => {
              return linkComponentUI(index, item);
            })}
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const FooterLinksSectionComponentV2 = (props: any) => {
  const { footerData, colorMode } = props;
  const [readMore, setReadMore] = useState(false);

  const bgColor = colorMode ? "#1e092d" : "#e8e6ea";
  const fontColor = colorMode ? "#ffffff" : "#1e092d";

  const showMore = () => {
    setReadMore((prev) => !prev);
  };

  const linkComponentUI = (index: number, item: any) => {
    return (
      <li key={"linkComponent_UI_" + index}>
        <CustomLinkComponent
          href={item.path}
          mobStyle={{ color: fontColor }}
          desktopStyle={{ color: fontColor }}
        >
          {item.text}
        </CustomLinkComponent>
      </li>
    );
  };

  const parentLinkComponentUI = (item) => {
    return (
      <div style={{ marginBottom: "5%" }}>
        <div className="row" style={{ margin: "0" }}>
          <CustomImageComponent
            layout="fixed"
            width={16}
            height={16}
            src={colorMode ? item.icon : item.iconLight}
            alt={""}
            className={styles.gamesCategoryIcon}
          />
          <div className={styles.gamesCategory}>{item.type}</div>
        </div>
        {item.games.map((subItem, index) => {
          return linkComponentUI(index, subItem);
        })}
      </div>
    );
  };

  return footerData ? (
    footerData.gamesSectionsV2 && footerData.gamesSectionsV2.length !== 0 ? (
      <div
        className={multipleClassName(
          "container-fluid",
          styles.footerLinksSectionV2
        )}
        style={{ backgroundColor: bgColor, color: fontColor }}
      >
        <div className={multipleClassName(styles.winzoFooterImg)}>
          <CustomLinkComponent href="/">
            <CustomImageComponent
              layout={"fill"}
              objectFit={"contain"}
              src={
                colorMode ? footerData.winzoLogoV2 : footerData.winzoLogoV2Light
              }
              alt="winzo games logo"
              className={styles.winzoFooterImg}
            />
          </CustomLinkComponent>
        </div>
        <div className={"row"}>
          <div
            className={multipleClassName("col-12", styles.headingFooterLinkV2)}
          >
            {footerData.gamesHeading}
          </div>
          <div
            className={styles.line}
            style={{ backgroundColor: colorMode ? "#ffffff33" : "#1e092d33" }}
          ></div>
        </div>
        <div className={"row"}>
          <div className={"col-12"}>
            <ul className={styles.footerUListV2}>
              {footerData.gamesSectionsV2.map((item, index) => {
                return !readMore
                  ? index < GAMES_TO_SHOW_INITIALLY_V2
                    ? parentLinkComponentUI(item)
                    : null
                  : parentLinkComponentUI(item);
              })}
            </ul>
          </div>
        </div>
        <div className={styles.readMoreDivV2} onClick={() => showMore()}>
          {!readMore ? (
            <div style={{ marginRight: "5px" }}>{footerData.readMoreText}</div>
          ) : (
            <div style={{ marginRight: "5px" }}>{footerData.readLessText}</div>
          )}
          <div
            className={styles.readMoreImageV2}
            style={{
              transform: `${!readMore ? "rotate(180deg)" : "rotate(0deg)"}`,
            }}
          >
            <CustomImageComponent
              src={
                colorMode
                  ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/showMoreIconV3.png"
                  : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/showMoreIconV3Light.png"
              }
              layout={"fill"}
              objectFit={"contain"}
              alt={"read-more-image"}
            />
          </div>
        </div>
      </div>
    ) : (
      <SubFooterLinksSectionComponentV1 {...props} />
    )
  ) : (
    <></>
  );
};

export default FooterLinksSectionComponentV2;
