import React, {useEffect, useRef} from "react";
import styles from "./CustomCarouselComponent.module.css"

const CustomCarouselComponent = (props: React.PropsWithChildren) => {

    const INTERVAL_TIME: number = 3000
    let scrollAmount: number = 0;
    let childrenArray = React.Children.toArray(props.children);
    const timeout: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef(undefined);

    // Children may contain false or null, so we should filter them
    // children may also contain string filled with spaces (in certain cases where we use jsx strings)
    let children = childrenArray.filter(child => {
        if (typeof child === "string") {
            return !!child.trim();
        }
        return !!child;
    });

    const enum CarouselType {
        Next,
        Prev
    }

    const handleResumeAndPause = () => {
        if (timeout.current) {
            clearInterval(timeout.current)
        }
        timeout.current = setInterval(handleAutoCarousel, INTERVAL_TIME);
    }

    useEffect(() => {
        if (children.length > 1) {
            timeout.current = setInterval(handleAutoCarousel, INTERVAL_TIME);
            let slides = document.getElementById("mySlides");
            slides?.addEventListener("scroll", () => {
                handleResumeAndPause()
            })
            return () => clearInterval(timeout.current)
        }
    }, [])

    const handleAutoCarousel = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            nextSlides()
        }
    }

    const setScrollAmount = (scrollLeft: number, childClientWidth: number, carouselType: CarouselType, loop: number = 1) => {
        if (loop < 10) {
            loop++
        } else {
            return
        }
        if (scrollLeft >= 0 && scrollLeft <= childClientWidth * (children.length - 1)) {
            if (scrollLeft > scrollAmount && scrollLeft < scrollAmount + childClientWidth) {
                if (carouselType === CarouselType.Prev) {
                    scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                }
            } else if (scrollLeft !== 0 && scrollLeft % childClientWidth !== 0) {
                if (carouselType === CarouselType.Next) {
                    if (scrollLeft >= scrollAmount) {
                        scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                    } else {
                        scrollAmount = Math.max(scrollAmount - childClientWidth, 0)
                    }
                    setScrollAmount(scrollLeft, childClientWidth, carouselType, loop)
                } else {
                    if (scrollLeft >= scrollAmount) {
                        scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                    } else {
                        scrollAmount = Math.max(scrollAmount - childClientWidth, 0)
                    }
                    setScrollAmount(scrollLeft, childClientWidth, carouselType, loop)
                }
            } else if (scrollLeft % childClientWidth === 0 && scrollLeft !== scrollAmount) {
                scrollAmount = scrollLeft
            }
        }
    }

    const nextSlides = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            setScrollAmount(slides.scrollLeft, slides.children[0].clientWidth, CarouselType.Next)
            if (scrollAmount === 0 && slides.scrollLeft > slides.children[0].clientWidth) {
                slides.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (scrollAmount < slides.children[0].clientWidth * (children.length - 1)) {
                slides.scrollTo({
                    top: 0,
                    left: Math.min(scrollAmount += slides.children[0].clientWidth, slides.children[0].clientWidth * (children.length - 1)),
                    behavior: 'smooth'
                });
            } else {
                scrollAmount = 0
                slides.scrollTo({
                    top: 0,
                    left: scrollAmount,
                    behavior: 'smooth'
                });
            }
        }
    };

    const prevSlides = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            setScrollAmount(slides.scrollLeft, slides.children[0].clientWidth, CarouselType.Prev)
            if (scrollAmount > 0 || (scrollAmount === 0 && slides.scrollLeft > 0)) {
                slides.scrollTo({
                    top: 0,
                    left: Math.max(scrollAmount -= slides.children[0].clientWidth, 0),
                    behavior: 'smooth'
                });
            }
        }
    };
    return (
        <div className={styles.slideshowContainer}>
            <div id={"mySlides"} className={styles.mySlides}>
                {
                    children.map((child, index) => {
                        return (
                            <div className={styles.child} key={"children_" + index}>
                                {child}
                            </div>
                        )
                    })
                }
            </div>

            {
                children.length > 1 && <>
                        <span className={styles.prev} onClick={() => {
                            handleResumeAndPause()
                            prevSlides()
                        }}>❮</span>
                    <span className={styles.next} onClick={() => {
                        handleResumeAndPause()
                        nextSlides()
                    }}>❯</span>
                </>
            }
        </div>
    )
}

export default CustomCarouselComponent