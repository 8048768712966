import {ICommonWithdrawalPartnerComponentProps} from "../../../lib/types/components";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import styles from "./CommonWithdrawalPartnerComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const CommonWithdrawalPartnerComponentV2 = (props: ICommonWithdrawalPartnerComponentProps) => {

    const data = props.withdrawalPartnerData;
    const variant = props.variant;
    const page = props.page

    return (
        data && Object.keys(data).length ?
            <div className={multipleClassName("col", styles.withdrawalContainer)}>
                <p>{data.text1}</p>
                <div>
                    <CustomImageComponent
                        layout={'fill'}
                        src={data.bannerImg.srcV2}
                        objectFit={"contain"}
                        alt={data?.bannerImg.alt || "banner Image"}
                        priority={true}
                    />
                </div>
            </div>
            :
            <div className={multipleClassName("col", styles.withdrawalContainer)}>
            </div>
    )
}

export default CommonWithdrawalPartnerComponentV2;